import { dailyGoalActionTypes } from './Constants';

export function getDailyGoalReportRequest() {
	return {
		type: dailyGoalActionTypes.GET_DAILY_GOAL_REPORT_REQUEST,
	};
}

export function getDailyGoalReportSuccess(dailyReport) {
	return {
		type: dailyGoalActionTypes.GET_DAILY_GOAL_REPORT_SUCCESS,
		payload: {
			dailyReport,
		},
	};
}

export function getDailyGoalReportError(error) {
	return {
		type: dailyGoalActionTypes.GET_DAILY_GOAL_REPORT_ERROR,
		error: true,
		payload: {
			error,
		},
	};
}

export function getDailyGoalReflectionsRequest() {
	return {
		type: dailyGoalActionTypes.GET_DAILY_GOAL_REFLECTIONS_REQUEST,
	};
}

export function getDailyGoalReflectionsSuccess(reflections) {
	return {
		type: dailyGoalActionTypes.GET_DAILY_GOAL_REFLECTIONS_SUCCESS,
		payload: {
			reflections,
		},
	};
}

export function getDailyGoalReflectionsError(error) {
	return {
		type: dailyGoalActionTypes.GET_DAILY_GOAL_REFLECTIONS_ERROR,
		payload: {
			error,
		},
	};
}

export function sendDailyGoalReflectionRequest(value) {
	return {
		type: dailyGoalActionTypes.SEND_DAILY_GOAL_REFLECTION_REQUEST,
		payload: {
			value,
		},
	};
}

export function sendDailyGoalReflectionSuccess() {
	return {
		type: dailyGoalActionTypes.SEND_DAILY_GOAL_REFLECTION_SUCCESS,
	};
}

export function sendDailyGoalReflectionError(error) {
	return {
		type: dailyGoalActionTypes.SEND_DAILY_GOAL_REFLECTION_ERROR,
		payload: {
			error,
		},
	};
}

export function getDailyGoalStickersRequest() {
	return {
		type: dailyGoalActionTypes.GET_DAILY_GOAL_STICKERS_REQUEST,
	};
}

export function getDailyGoalStickersSuccess(dailyGoalStickers) {
	return {
		type: dailyGoalActionTypes.GET_DAILY_GOAL_STICKERS_SUCCESS,
		payload: {
			dailyGoalStickers,
		},
	};
}

export function getDailyGoalStickersError(error) {
	return {
		type: dailyGoalActionTypes.GET_DAILY_GOAL_STICKERS_ERROR,
		error: true,
		payload: {
			error,
		},
	};
}

export function sendDailyGoalStickerRequest(sticker) {
	return {
		type: dailyGoalActionTypes.SEND_DAILY_GOAL_STICKER_REQUEST,
		payload: {
			sticker,
		},
	};
}

export function sendDailyGoalStickerSuccess(userStickers) {
	return {
		type: dailyGoalActionTypes.SEND_DAILY_GOAL_STICKER_SUCCESS,
		payload: {
			userStickers,
		},
	};
}

export function sendDailyGoalStickerError(error) {
	return {
		type: dailyGoalActionTypes.SEND_DAILY_GOAL_STICKER_ERROR,
		error: true,
		payload: {
			error,
		},
	};
}

export function getUserStickersRequest() {
	return {
		type: dailyGoalActionTypes.GET_USER_STICKERS_REQUEST,
	};
}

export function getUserStickersSuccess(userStickers) {
	return {
		type: dailyGoalActionTypes.GET_USER_STICKERS_SUCCESS,
		payload: {
			userStickers,
		},
	};
}

export function getUserStickersError(error) {
	return {
		type: dailyGoalActionTypes.GET_USER_STICKERS_ERROR,
		error: true,
		payload: {
			error,
		},
	};
}

export function getProfileStickersRequest() {
	return {
		type: dailyGoalActionTypes.GET_PROFILE_STICKERS_REQUEST,
	};
}

export function getProfileStickersSuccess(profileStickers) {
	return {
		type: dailyGoalActionTypes.GET_PROFILE_STICKERS_SUCCESS,
		payload: {
			profileStickers,
		},
	};
}

export function getProfileStickersError() {
	return {
		type: dailyGoalActionTypes.GET_PROFILE_STICKERS_ERROR,
		error: true,
	};
}

export function collectRewardRequest(id, body) {
	return {
		type: dailyGoalActionTypes.COLLECT_REWARD_REQUEST,
		payload: {
			id,
			body,
		},
	};
}

export function collectRewardSuccess() {
	return {
		type: dailyGoalActionTypes.COLLECT_REWARD_SUCCESS,
	};
}

export function collectRewardError() {
	return {
		type: dailyGoalActionTypes.COLLECT_REWARD_ERROR,
		error: true,
	};
}

export function updateRewardRequest(id, body, callback) {
	return {
		type: dailyGoalActionTypes.UPDATE_REWARD_REQUEST,
		payload: {
			id,
			body,
			callback,
		},
	};
}

export function updateRewardSuccess() {
	return {
		type: dailyGoalActionTypes.UPDATE_REWARD_SUCCESS,
	};
}

export function updateRewardError() {
	return {
		type: dailyGoalActionTypes.UPDATE_REWARD_ERROR,
		error: true,
	};
}

export function getDailyGoalProgressRequest() {
	return {
		type: dailyGoalActionTypes.GET_DAILY_GOAL_PROGRESS_REQUEST,
	};
}

export function getDailyGoalProgressSuccess(dailyGoalProgress) {
	return {
		type: dailyGoalActionTypes.GET_DAILY_GOAL_PROGRESS_SUCCESS,
		payload: {
			dailyGoalProgress,
		},
	};
}

export function getDailyGoalProgressError(error) {
	return {
		type: dailyGoalActionTypes.GET_DAILY_GOAL_PROGRESS_ERROR,
		payload: {
			error,
		},
	};
}
