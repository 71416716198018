import { put, takeLatest, all, select, call } from 'redux-saga/effects';
import { learningPathActionTypes, TILE_STATUSES } from './Constants';
import { getUserId } from './Selectors';
import { processRequest } from '../../services/Api';
import { historyPush } from '../../services/History';
import { isEmptyLearningPath, updateLearningPath } from './Helper';
import { STICKER_REWARDS } from '../../components/stickers/Constants';
import * as learningPathActions from './Actions';
import * as errorHandlerActions from '../../components/errorHandler/Actions';
import * as sharedActions from '../../shared/Actions';

export default function* () {
	yield all([
		yield takeLatest(
			learningPathActionTypes.GET_LEARNING_PATH_REQUEST,
			handleGetLearningPathRequest,
		),
		yield takeLatest(
			learningPathActionTypes.UPDATE_ONBOARDING_DATA_REQUEST,
			handleUpdateOnboardingDataRequest,
		),
		yield takeLatest(
			learningPathActionTypes.SEND_COLLECTED_REWARD_REQUEST,
			handleSendCollectedRewardRequest,
		),
	]);
}

export function* handleGetLearningPathRequest() {
	try {
		let learningPathResponse = [];

		const response1 = yield call(processRequest, 'user_learning_paths');
		learningPathResponse = response1.data;

		if (isEmptyLearningPath(learningPathResponse?.learning_path)) {
			yield call(processRequest, 'user_goals/generate_path', 'POST');
			const response2 = yield call(processRequest, 'user_learning_paths');
			learningPathResponse = response2.data;
		}

		if (isEmptyLearningPath(learningPathResponse?.learning_path)) {
			yield put(errorHandlerActions.handleError());
			return;
		}

		learningPathResponse.learning_path = updateLearningPath(learningPathResponse.learning_path);

		yield put(learningPathActions.getLearningPathSuccess(learningPathResponse));
	} catch (e) {
		if (e.response.status === 404) {
			yield call(historyPush, '/doel-instellen-nieuw');
		} else {
			yield put(errorHandlerActions.handleError(e));
		}
		yield put(learningPathActions.getLearningPathError());
	}
}

export function* handleUpdateOnboardingDataRequest({ payload }) {
	try {
		const { key, status } = payload || {};

		const userId = yield select(getUserId);

		yield call(processRequest, `users/${userId}`, 'PUT', {
			user: { [key]: true },
		});

		const { data: userData } = yield call(processRequest, 'get_user_data');
		yield put(sharedActions.getUserDataSuccess(userData));

		if (status === TILE_STATUSES.TILE_IN_PROGRESS) {
			const { data } = yield call(processRequest, 'user_learning_paths');
			data.learning_path = updateLearningPath(data.learning_path);
			yield put(learningPathActions.getLearningPathSuccess(data));
			yield put(learningPathActions.setFinishTileAnimation(true));
		}
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}

export function* handleSendCollectedRewardRequest({ payload }) {
	try {
		const { id, body, rewardType } = payload || {};

		yield call(processRequest, `user_stickers/${id}/collect_reward`, 'POST', body);

		const { data } = yield call(processRequest, 'user_learning_paths');

		data.learning_path = updateLearningPath(data.learning_path);

		if (rewardType === STICKER_REWARDS.SHORTER_LEARNING_PATH) {
			yield put(learningPathActions.setNewSteps(data.learning_path));
			yield put(learningPathActions.setShorterLearningPathAnimation(true));
		} else if (rewardType === STICKER_REWARDS.SKIP_TILE) {
			yield put(learningPathActions.getLearningPathSuccess(data));
			yield put(learningPathActions.setSkipTileAnimation(true));
		} else {
			yield put(learningPathActions.getLearningPathSuccess(data));
			yield put(learningPathActions.setFinishTileAnimation(true));
		}
	} catch (e) {
		yield put(errorHandlerActions.handleError(e, 'notification'));
	}
}
