import keyMirror from 'keymirror';

export const progressActionTypes = keyMirror({
	GET_PROGRESS_OVERVIEW_REQUEST: null,
	GET_PROGRESS_OVERVIEW_SUCCESS: null,
	GET_PROGRESS_OVERVIEW_ERROR: null,

	GET_PROGRESS_DETAILS_REQUEST: null,
	GET_PROGRESS_DETAILS_SUCCESS: null,
	GET_PROGRESS_DETAILS_ERROR: null,

	TOGGLE_MODAL_TYPE: null,
	CLEAR_PROGRESS_DETAILS: null,
});

export const MODAL_TYPES = {
	DEVELOPMENT: 'development',
	MASTERY: 'mastery',
	EXPLANATION: 'explanation',
};

export const GROUP_STAGE_MAP_PROGRESS_BAR = {
	3: {
		start: 1,
		middle: 2,
		end: 3,
	},
	4: {
		start: 4,
		middle: 5,
		end: 6,
	},
	5: {
		start: 7,
		middle: 8,
		end: 9,
	},
	6: {
		start: 10,
		middle: 11,
		end: 12,
	},
	7: {
		start: 13,
		middle: 14,
		end: 15,
	},
	8: {
		start: 16,
		middle: 17,
		end: 18,
	},
};

export const PROGRESS_INFO_TYPE = {
	DEVELOPMENT: 'development',
	MASTERY: 'mastery',
};
